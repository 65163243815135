import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import DeliveryTruck from '../../../Assets/OrderScreen/Delivery_Truck.svg';
import Pickup from '../../../Assets/OrderScreen/Pickup.svg';
import Cancel from '../../../Assets/OrderScreen/cancle.svg';
import Refund from '../../../Assets/OrderScreen/refund.svg';

import Accepted from '../../../Assets/OrderScreen/Accepted.svg';
import Packing from '../../../Assets/OrderScreen/Packing.svg';
import Ready from '../../../Assets/OrderScreen/Ready.svg';
import Shipped from '../../../Assets/OrderScreen/Shipped.svg';
import Delivered from '../../../Assets/OrderScreen/Delivered.svg';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../utils';

const SingleCloseOrder = ({ Order, orderMethod }) => {
  const [deliveryStatus, setDeliveryStatus] = useState();
  const [pickupStatus, setPickupStatus] = useState();
  const [orderStatus, setOrderStatus] = useState();

  // console.log("deliverivery status", deliveryStatus)
  // console.log("picup Status", pickupStatus)
  // console.log("orderStatus", orderStatus)
  // console.log("orderStatus", Order.m_status)

  const Navigate = useNavigate();
  const handlePlaceOrder = () => {
    const orderId = Order.order_id;
    const merchantId = Order.merchant_id;

    Navigate('/orderSummeryPage', {
      state: {
        orderId: orderId,
        merchantId: merchantId,
        orderMethod: Order.order_method,
      },
    });
  };

  const AllOrder = () => {
    // console.log(Order)

    if (Order && Order.order_method === 'pickup') {
      setPickupStatus(true);
    } else {
      setPickupStatus(false);
    }

    if (Order && Order.payment_id === 'Cash') {
      setOrderStatus(true);
    } else {
      setOrderStatus(false);
    }
  };
  // AllOrder()

  useEffect(() => {
    AllOrder();
  }, []);
  useEffect(() => {
    if (Order && Order.m_status === '1' && pickupStatus === false) {
      setDeliveryStatus('Accepted');
    } else if (Order && Order.m_status === '2' && pickupStatus === false) {
      setDeliveryStatus('Packing');
      // } else if (Order && Order.m_status === "3" && pickupStatus === false) {
      //     setDeliveryStatus("Ready")
    } else if (Order && Order.m_status === '3' && pickupStatus === false) {
      setDeliveryStatus('Shipped');
    } else if (Order && Order.m_status === '4' && pickupStatus === false) {
      setDeliveryStatus('Delivered');
    } else if (Order && Order.m_status === '5' && pickupStatus === false) {
      setDeliveryStatus('Cancel');
    } else if (Order && Order.m_status === '7' && pickupStatus === false) {
      setDeliveryStatus('Refund');
    } else if (Order && Order.m_status === '1' && pickupStatus === true) {
      setDeliveryStatus('Accepted');
    } else if (Order && Order.m_status === '2' && pickupStatus === true) {
      setDeliveryStatus('Packing');
    } else if (Order && Order.m_status === '6' && pickupStatus === true) {
      setDeliveryStatus('Ready');
    } else if (Order && Order.m_status === '4' && pickupStatus === true) {
      // console.log("console picup")
      setDeliveryStatus('pickup');
    } else if (Order && Order.m_status === '5' && pickupStatus === true) {
      setDeliveryStatus('Cancel');
    } else if (Order && Order.m_status === '7' && pickupStatus === true) {
      setDeliveryStatus('Refund');
    }
  }, [Order, Order.m_status, pickupStatus]);

  return (
    <>
      {Order && Order.m_status && Order.order_method && (
        <div
          className="single-open-order-div cursor-pointer"
          onClick={handlePlaceOrder}
        >
          <div className="single-open-order-delivery-status">
            {pickupStatus ? (
              <Button className="picked-up-button">
                {' '}
                <img src={Pickup} alt="Pickup" /> Pickup{' '}
              </Button>
            ) : (
              <Button className="delivery-button">
                {' '}
                <img src={DeliveryTruck} alt="Delivery" /> Delivery{' '}
              </Button>
            )}
          </div>
          <div className="single-open-order-content">
            <div className="single-order-id">
              <span>Order ID:</span>
              <p>{Order.order_id}</p>
            </div>
            <div className="single-order-id single-order-date-time">
              <span>Date & Time:</span>
              <p> {formatDate(Order.date_time)} </p>
            </div>
          </div>

          <div className="single-open-order-amount">
            <div className="single-open-order-payment-button">
              {/* {
                                    deliveryStatus && deliveryStatus === "Accepted" ?
                                        <Button className='accepted-button'>  <img src={Accepted} alt="Delivery-Truck" />  Accepted </Button> : ""
                                }
                                {
                                    deliveryStatus && deliveryStatus === "Packing" ?
                                        <Button className='packing-button'>  <img src={Packing} alt="Delivery-Truck" />  Packing </Button> : ""
                                }
                                {
                                    deliveryStatus && deliveryStatus === "Ready" ?
                                        <Button className='ready-button'>  <img src={Ready} alt="Delivery-Truck" />  Ready </Button> : ""
                                }
                                {
                                    deliveryStatus && deliveryStatus === "Shipped" ?
                                        <Button className='shipped-button'>  <img src={Shipped} alt="Delivery-Truck" />  Shipped </Button> : ""
                                }
                                {
                                    deliveryStatus && deliveryStatus === "Delivered" ?
                                        <Button className='delivered-button'>  <img src={Delivered} alt="Delivery-Truck" />  Delivered </Button> : ""
                                }
                                {
                                    deliveryStatus && deliveryStatus === "pickup" ?
                                        <Button className='delivered-button'>  <img src={Delivered} alt="pickup" />  Picked-up </Button> : ""
                                } */}

              {deliveryStatus && deliveryStatus === 'Accepted' ? (
                <Button className="accepted-button">
                  {' '}
                  <img src={Accepted} alt="Accepted" /> Accepted{' '}
                </Button>
              ) : (
                ''
              )}
              {deliveryStatus && deliveryStatus === 'Packing' ? (
                <Button className="packing-button">
                  {' '}
                  <img src={Packing} alt="Packing" /> Packing{' '}
                </Button>
              ) : (
                ''
              )}
              {deliveryStatus && deliveryStatus === 'Ready' ? (
                <Button className="ready-button">
                  {' '}
                  <img src={Ready} alt="Ready" /> Ready{' '}
                </Button>
              ) : (
                ''
              )}
              {deliveryStatus && deliveryStatus === 'Shipped' ? (
                <Button className="shipped-button">
                  {' '}
                  <img src={Shipped} alt="Shipped" /> Shipped{' '}
                </Button>
              ) : (
                ''
              )}
              {deliveryStatus && deliveryStatus === 'Delivered' ? (
                <Button className="delivered-button">
                  {' '}
                  <img src={Delivered} alt="Delivery-Truck" /> Delivered{' '}
                </Button>
              ) : (
                ''
              )}
              {deliveryStatus && deliveryStatus === 'pickup' ? (
                <Button className="delivered-button">
                  {' '}
                  <img src={Delivered} alt="pickup" /> Picked-up{' '}
                </Button>
              ) : (
                ''
              )}
              {deliveryStatus && deliveryStatus === 'Cancel' ? (
                <Button className="cancle-button">
                  {' '}
                  <img src={Cancel} alt="Cancelled" /> Cancelled{' '}
                </Button>
              ) : (
                ''
              )}
              {deliveryStatus && deliveryStatus === 'Refund' ? (
                <Button className="refund-button">
                  {' '}
                  <img src={Refund} alt="Refund" /> Refunded{' '}
                </Button>
              ) : (
                ''
              )}
            </div>

            <div className="single-open-order-payment-status">
              {orderStatus ? (
                <div className=" payment-am-status">
                  <span className="payment-cod-pending"> COD Pending </span>
                  <p> {Order.subtotal} </p>{' '}
                </div>
              ) : (
                <div className="payment-am-status">
                  <span className="payment-paid "> Paid </span>
                  <p> {Order.subtotal} </p>{' '}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleCloseOrder;
