import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Validation from '../../../Constants/Validation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  BASE_URL_SANDBOX,
  REGISTER_CUSTOMER_NEW,
} from '../../../Constants/Config';
import {
  setAuthentication,
  setAuthUser,
} from '../../../redux/features/Authentication/Customer/authUserSlice';
import UseCookieStorage from '../../../Constants/UseCookieStorage';
import Cookies from 'js-cookie';

const RegisterCustomerFormLogic = () => {
  const dispatch = useDispatch();
  const {
    validateEmail,
    validatePassword,
    validatePhoneNumber,
    validateConfrimPassword,
    validateFirstName,
    validateLastName,
    validateCaptcha,
  } = Validation();
  let merchant_store_id = localStorage.getItem('merchant_id');
  let store_method_id = localStorage.getItem('orderRecord');
  const [submitmessage, setsubmitmessage] = useState('');
  const Navigate = useNavigate();
  const scrollRef = useRef(null);
  const [openAlert, setOpenAlert] = useState(false);

  const location = useLocation();
  const mymerchantId = location.state?.merchantId || '';
  const orderMethod = location.state?.orderMethod || '';

  const page = location.state?.page || '';

  const EmailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const emoji =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

  const Space = /^[^\s]+$/i;
  const PhoneNo = /^\d{10}$/;
  const Nameval = /^[a-zA-Z]+$/;

  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    username: '',
    password: '',
    confirmpassword: '',
    phone: '',
    recaptcha: '',
    errors: {
      firstname: '',
      lastname: '',
      username: '',
      password: '',
      confirmpassword: '',
      phone: '',
      recaptcha: '',
    },
    // cursorposition: {
    //   firstname: 0,
    //   lastname: 0,
    //   username: 0,
    //   password: 0,
    //   confirmpassword: 0,
    //   phone: 0,
    // },
  });

  const handleScrollClick = async () => {
    if (scrollRef !== '') {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCustomerRegisterInput = async (event) => {
    let { errors } = values;
    let { cursorposition } = values;
    const fieldName = event.target.name;
    const fieldValue =
      fieldName === 'phone'
        ? event.target.value.replace(/[^0-9]/g, '')
        : event.target.value;
    let myconfString = 're-enter password';
    const newCursorPosition = event.target.selectionStart;

    switch (fieldName) {
      case 'firstname':
        // cursorposition.firstname = newCursorPosition;
        // await validateFirstName(fieldValue, errors);
        if (fieldValue === '') {
          errors.firstname = 'Please enter First Name ';
        } else if (emoji.test(fieldValue)) {
          errors.firstname = 'Emoji not allowed';
        } else if (!Space.test(fieldValue)) {
          errors.firstname = 'Space is not allow';
        } else if (!Nameval.test(fieldValue)) {
          errors.firstname = 'Alphabets only';
        } else {
          errors.firstname = '';
        }
        break;
      case 'lastname':
        // cursorposition.lastname = newCursorPosition;
        // await validateLastName(fieldValue, errors);
        if (fieldValue === '') {
          errors.lastname = 'Please enter Last Name ';
        } else if (emoji.test(fieldValue)) {
          errors.lastname = 'Emoji not allowed';
        } else if (!Space.test(fieldValue)) {
          errors.lastname = 'Space is not allow';
        } else if (!Nameval.test(fieldValue)) {
          errors.lastname = 'Alphabets only';
        } else {
          errors.lastname = '';
        }
        break;
      case 'username':
        // cursorposition.username = newCursorPosition;
        // await validateEmail(fieldValue, errors);
        if (fieldValue === '') {
          errors.username = 'Please enter email';
        } else if (emoji.test(fieldValue)) {
          errors.username = 'Emoji not allowed';
        } else if (!EmailReg.test(fieldValue)) {
          errors.username = 'Please enter valid email';
        } else {
          errors.username = '';
        }
        break;
      case 'password':
        // cursorposition.password = newCursorPosition;
        // await validatePassword(fieldValue, errors);
        if (fieldValue === '') {
          errors.password = 'Please enter password';
        } else if (emoji.test(fieldValue)) {
          errors.password = 'Emoji not allowed';
        } else if (!Space.test(fieldValue)) {
          errors.password = 'Space is not allow';
        } else {
          errors.password = '';
        }
        break;
      case 'confirmpassword':
        // cursorposition.confirmpassword = newCursorPosition;
        // await validateConfrimPassword(
        //   fieldValue,
        //   values.password,
        //   myconfString,
        //   errors,
        // );

        if (fieldValue === '') {
          errors.confirmpassword = `Please enter ${myconfString}`;
        } else if (emoji.test(fieldValue)) {
          errors.confirmpassword = 'Emoji not allowed';
        } else if (fieldValue !== values.password) {
          errors.confirmpassword = 'Not matching with password';
        } else {
          errors.confirmpassword = '';
        }
        break;
      case 'phone':
        // cursorposition.phone = newCursorPosition;
        // await validatePhoneNumber(fieldValue, errors);
        if (fieldValue === '') {
          errors.phone = 'Please enter phone number';
        } else if (emoji.test(fieldValue)) {
          errors.phone = 'Emoji not allowed';
        } else if (!PhoneNo.test(fieldValue)) {
          errors.phone = 'Phone no not valid';
        } else {
          errors.phone = '';
        }
        break;
      default:
        break;
    }

    setValues((prevValues) => ({
      errors,
      // cursorposition,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  };

  const validateNumberInput = (input) => {
    input.value = input.value.replace(/[^0-9]/g, '');
  };
  // ===============================================
  // ================================================
  const userData = Cookies.get('userLoginData');
  useEffect(() => {
    const handleBackNavigation = () => {
      const currentPath = window.location.pathname;
      //  console.log(currentPath)
      // console.log(currentPath);
      // console.log( localStorage.getItem('userLoginData'))AuthLogin localStorage.getItem('userLoginData') !==""
      if (currentPath === '/register' && !!userData) {
        // Navigate('/')
        // //  Navigate(-1)merchant_store_id,store_method_id
        if (
          merchant_store_id !== '' &&
          merchant_store_id !== null &&
          merchant_store_id !== undefined &&
          store_method_id !== '' &&
          store_method_id !== undefined &&
          store_method_id !== null
        ) {
          Navigate(
            `/merchant/${merchant_store_id}?orderMethod=${store_method_id}`,
          );
        } else {
          Navigate('/');
        }
      }
    };
    handleBackNavigation();
    window.addEventListener('popstate', handleBackNavigation);

    return () => {
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, []);
  // ================================================
  // ==============================================

  const handleCustomerRegister = async (e) => {
    e.preventDefault();
    let myconfString = 're-enter password';
    let { errors } = values;

    await validateFirstName(values.firstname, errors);
    await validateLastName(values.lastname, errors);
    await validatePhoneNumber(values.phone, errors);
    await validateEmail(values.username, errors);
    await validatePassword(values.password, errors);
    await validateCaptcha(values.recaptcha, errors);
    await validateConfrimPassword(
      values.confirmpassword,
      values.password,
      myconfString,
      errors,
    );

    if (
      errors.firstname === '' &&
      errors.lastname === '' &&
      errors.username === '' &&
      errors.phone === '' &&
      errors.password === '' &&
      errors.confirmpassword === '' &&
      errors.recaptcha === ''
    ) {
      const data = {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.username.toLowerCase(),
        phone: values.phone,
        password: values.password,
        guest: 'No',
        ip_address: '',
        merchant_id: '',
      };

      try {
        const response = await axios.post(
          BASE_URL_SANDBOX + REGISTER_CUSTOMER_NEW,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        if (response.data.status && response.data.status === 200) {
          dispatch(
            setAuthUser(JSON.parse(JSON.stringify(response.data.result))),
          );
          UseCookieStorage(
            'userLoginData',
            JSON.stringify(response.data.result),
            new Date(new Date().getTime() + 2 * 60 * 60 * 1000),
          );
          dispatch(setAuthentication(true));

          //console.log(mymerchantId, orderMethod);
          if (mymerchantId !== '' && orderMethod !== '' && page === 'cart') {
            Navigate(
              `/payment-cart/${mymerchantId}?orderMethod=${orderMethod}`,
            );
          } else if (
            mymerchantId !== '' &&
            orderMethod !== '' &&
            page === 'onlineordering'
          ) {
            Navigate(`/merchant/${mymerchantId}?orderMethod=${orderMethod}`);
          } else if (
            merchant_store_id !== null &&
            merchant_store_id !== undefined &&
            store_method_id !== undefined &&
            store_method_id !== null
          ) {
            Navigate(
              `/merchant/${merchant_store_id}?orderMethod=${store_method_id}`,
            );
          } else {
            Navigate('/');
          }
        } else {
          await handleScrollClick();
          setsubmitmessage(response.data.message);
          setOpenAlert(true);
        }
      } catch (error) {
        //console.error("Registration error:", error);
        // Handle registration error
      }
    }

    setValues((prevState) => ({
      ...prevState,
      errors,
    }));
  };

  return {
    handleCustomerRegisterInput,
    values,
    handleCustomerRegister,
    submitmessage,
    setsubmitmessage,
    openAlert,
    setOpenAlert,
    scrollRef,
    setValues,
    validateNumberInput,
  };
};

export default RegisterCustomerFormLogic;
