import React, { memo } from 'react';

const ProductInfo = memo(function ProductInfo({
  singleProductData,
  varientList,
  singleVariantData,
  variantDataState,
  oldProductPrice,
}) {
  return (
    <div className="quickvee-single-product-main-detail">
      <h1>{singleProductData?.title}</h1>
      <p style={{ height: '36px' }}>
        <span>
          {varientList?.length > 0
            ? singleVariantData
              ? `$${singleVariantData?.price}`
              : variantDataState.loading && `$${oldProductPrice}`
            : null}
          {(varientList?.length <= 0 || !varientList) &&
            singleProductData &&
            `$${singleProductData?.price}`}
        </span>{' '}
        <del>
          {singleVariantData?.compare_price > singleVariantData?.price
            ? `$${singleVariantData?.compare_price}`
            : singleProductData?.compare_price > 0
            ? `$${singleProductData?.compare_price}`
            : ''}
        </del>
      </p>
    </div>
  );
});

export default ProductInfo;
