import React, { useEffect, useState } from 'react';
import MainAuthentication from './Components/Authentication/Main/MainAuthentication';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import MainOrder from './Components/Order/Main/MainOrder';
import MainCart from './Components/Payment/Main/MainCart';
import MainSingleStore from './Components/Store/SingleStore/MainSingleStore';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAuthUser,
  setAuthentication,
  setGuestLogin,
} from './redux/features/Authentication/Customer/authUserSlice';
import OrderSummeryPage from './Components/orderSummeryPage/orderSummery';
import { fetchAllStates } from '../src/redux/features/Home/allStatesSlice';

import MainHome from './Components/MainHome/Main';
import Cookies from 'js-cookie';
import PageNotFound from './Components/ReusableComponent/PageNotFound';

const Main = () => {
  const localUserData = Cookies.get('userLoginData');
  const isGuest = Cookies.get('is_guest');

  const dispatch = useDispatch();
  const location = useLocation();
  const StateList = useSelector((state) => state?.allStatesData?.allStateList);

  useEffect(() => {
    if (StateList.length <= 0) {
      dispatch(fetchAllStates());
    }

    if (localUserData) {
      dispatch(setAuthUser(JSON.parse(localUserData)));
      dispatch(setAuthentication(true));
      dispatch(setGuestLogin(isGuest));
    }
  }, [localUserData]);

  // removing orderStatus from sessionStorage if user is not on Checkout Form Page
  useEffect(() => {
    if (!location.pathname.startsWith('/payment-cart')) {
      if (sessionStorage.getItem('orderStatus')) {
        sessionStorage.removeItem('orderStatus');
      }
    }
  }, [location]);

  return (
    <>
      <Routes>
        <Route exact path="/" element={<MainHome visible={'home'} />} />
        <Route
          exact
          path="/category/:productCode"
          element={<MainHome visible={'category'} />}
        />
        <Route
          exact
          path="/forgot-password"
          element={<MainAuthentication visible={'forgot-password'} />}
        />
        <Route
          exact
          path="/customer-login"
          element={<MainAuthentication visible={'login'} />}
        />
        <Route
          exact
          path="/about-us"
          element={<MainHome visible={'AboutUs'} />}
        />
        <Route
          exact
          path="/privacy-policy"
          element={<MainHome visible={'policy'} />}
        />
        <Route
          exact
          path="/terms-condition"
          element={<MainHome visible={'termconditions'} />}
        />
        <Route
          exact
          path="/merchantlogin"
          element={<MainAuthentication visible={'merchantlogin'} />}
        />
        <Route
          exact
          path="/changePassword"
          element={<MainAuthentication visible={'changePassword'} />}
        />
        <Route
          exact
          path="/register"
          element={<MainAuthentication visible={'register'} />}
        />
        <Route
          exact
          path="/guest-checkout"
          element={<MainAuthentication visible={'guest-checkout'} />}
        />
        <Route exact path="/order" element={<MainOrder />} />
        <Route
          exact
          path="/order/order-history"
          element={<MainOrder visible={'orderhistory'} />}
        />
        <Route
          exact
          path="/order/profile"
          element={<MainOrder visible={'profile'} />}
        />
        <Route
          exact
          path="/order/storecreditzone"
          element={<MainOrder visible={'StoreCreditZone'} />}
        />
        <Route
          exact
          path="/order/loyaltypointzone"
          element={<MainOrder visible={'LoyaltyPointZone'} />}
        />
        {/* <Route exact path="/order/profile"            render={(props) => <MainOrder {...props} visible={"profile"} />         }  /> */}
        {/* <Route exact path="/order/storecreditzone"  render={(props) => <MainOrder {...props} visible={"StoreCreditZone"}  />} />
        <Route exact path="/order/loyaltypointzone" render={(props) => <MainOrder {...props} visible={"LoyaltyPointZone"}  />} /> */}
        <Route exact path="/payment-cart/:productCode" element={<MainCart />} />
        <Route exact path="/store" element={<MainSingleStore />} />
        <Route
          exact
          path="/wishlist/:productCode"
          element={<MainSingleStore visible={'wishlist'} />}
        />
        <Route
          exact
          path="/merchant/:productCode"
          element={<MainSingleStore visible={'merchant'} />}
        />
        <Route
          exact
          path="/product/:productCode"
          element={<MainSingleStore visible={'product'} />}
        />
        <Route
          exact
          path="/orderSummeryPage"
          element={<OrderSummeryPage visible={'orderSummery'} />}
        />
        <Route path="*" Component={PageNotFound} />
      </Routes>
    </>
  );
};

export default Main;
