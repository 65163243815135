import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { FormControl, TextField } from "@mui/material";
import BasicInfoLogic from "./BasicInfoLogic";


const BasicInfo = ({ userData }) => {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const inputRefs = useRef({});
  const handleClose = () => {
    setOpen(false);
    values.errors.firstname = "";
    values.errors.lastname = "";
    values.errors.phone = "";
    setsubmitmessage("")
  }
  const { handleBasicInfoInput, values, handleBasicInfoForm, submitmessage, setsubmitmessage ,handleKeyDown } = BasicInfoLogic({ handleClose, userData });


  useEffect(() => {
    for (const fieldName in values.cursorposition) {
      const inputField = inputRefs.current[fieldName];
      if (inputField) {
        inputField.setSelectionRange(values.cursorposition[fieldName], values.cursorposition[fieldName]);
      }
    }
  }, [values]);

  return (
    <>
      <div>
        <div style={{ cursor: 'pointer' }} className="modal-main-button BasicInfo-btn-padding" onClick={handleOpen}>
          Edit
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="basic-modal">
            <div id="modal-modal-title">
              <span>Basic Info</span>
              <CloseIcon onClick={handleClose} className="modal-close-icon " />
            </div>

            <form id="modal-modal-description" className="modal-forms">
              <div className="row" >
                <div className="col-md-12"
                 style={{ position: 'relative', marginBottom: '24px'}}
                >
                  <div>
                    <TextField 
                      label="First Name"
                      className="input-field"
                      variant="outlined"
                      autoComplete="off"
                      name="firstname"
                      onChange={handleBasicInfoInput}
                      value={values.firstname}
                      // inputProps={{
                      //   "data-field": "firstname",
                      //   autoComplete: "off",
                      //   ref: (input) => (inputRefs.current["firstname"] = input), // Store the ref in a ref object
                      //   selectionstart: values.cursorposition.firstname,
                      // }}
                    />
                  </div>
                  <span className="input-error">{values.errors.firstname}</span>
                </div>
              </div>
              {/* <FormControl className="password-margin-bottom ">
                <div className="modal-form-single-item basicInfo-input">
                  <TextField
                    label="First Name"
                    className="modal-form-single-input"
                    variant="filled"
                    size="small"
                    autoComplete="off"
                    name="firstname"
                    onChange={handleBasicInfoInput}
                    value={values.firstname}
                    inputProps={{
                      "data-field": "firstname",
                      autoComplete: "off",
                      ref: (input) => (inputRefs.current["firstname"] = input), // Store the ref in a ref object
                      selectionstart: values.cursorposition.firstname,
                    }}
                  />
                  <span className="input-error basicinfo-error">{values.errors.firstname}</span>
                </div>
              </FormControl> */}
              <div  className="row" >
              <div
              className="col-md-12"
              style={{ position: 'relative', marginBottom: '24px' }}
              >
                <div>
                <TextField
                    label=" Last Name"
                    className="input-field"
                    variant="outlined"
                  
                    autoComplete="off"
                    name="lastname"
                    onChange={handleBasicInfoInput}
                    value={values.lastname}
                    // inputProps={{
                    //   "data-field": "lastname",
                    //   autoComplete: "off",
                    //   ref: (input) => (inputRefs.current["lastname"] = input), // Store the ref in a ref object
                    //   selectionstart: values.cursorposition.lastname,
                    // }}
                  />
                </div>
                <span className="input-error basicinfo-error">{values.errors.lastname}</span>
              </div>
              </div>
              {/* <FormControl className="password-margin-bottom ">
                <div className="modal-form-single-item basicInfo-input">
                  <TextField
                    label=" Last Name"
                    className="modal-form-single-input"
                    variant="filled"
                    size="small"
                    autoComplete="off"
                    name="lastname"
                    onChange={handleBasicInfoInput}
                    value={values.lastname}
                    inputProps={{
                      "data-field": "lastname",
                      autoComplete: "off",
                      ref: (input) => (inputRefs.current["lastname"] = input), // Store the ref in a ref object
                      selectionstart: values.cursorposition.lastname,
                    }}
                  />

                  <span className="input-error basicinfo-error">{values.errors.lastname}</span>
                </div>
              </FormControl> */}
              <div className="row"> 
              <div
               className="col-md-12"
               style={{ position: 'relative', marginBottom: '24px'}}
              >
                <div>
                <TextField
                    label="Phone Number"
                    className="input-field"
                    variant="outlined"
                  
                    autoComplete="off"
                    name="phone"
                    onChange={handleBasicInfoInput}
                    value={values.phone}
                    onKeyDown={handleKeyDown}
                    inputProps={{
                       maxLength: 10 ,
                      // "data-field": "phone",
                      // autoComplete: "off",
                      // ref: (input) => (inputRefs.current["phone"] = input), // Store the ref in a ref object
                      // selectionstart: values.cursorposition.phone,
                    }}
                  />
                </div>
                <span className="input-error basicinfo-error">{values.errors.phone}</span>
              </div>
              </div>
              {/* <FormControl className="password-margin-bottom ">
                <div className="modal-form-single-item basicInfo-input">
                  <TextField
                    label="Phone Number"
                    className="modal-form-single-input"
                    variant="filled"
                    size="small"
                    autoComplete="off"
                    name="phone"
                    onChange={handleBasicInfoInput}
                    value={values.phone}
                    onKeyDown={handleKeyDown}
                    inputProps={{
                       maxLength: 10 ,
                      "data-field": "phone",
                      autoComplete: "off",
                      ref: (input) => (inputRefs.current["phone"] = input), // Store the ref in a ref object
                      selectionstart: values.cursorposition.phone,
                    }}
                  />
                  <span className="input-error basicinfo-error">{values.errors.phone}</span>
                </div>
              </FormControl> */}
              {submitmessage ? <span className="input-error error-on-submit">{submitmessage}</span> : ""}
              <div className="modal-footer">
                <Button className="modal-cancle-btn" onClick={handleClose}>Cancel</Button>
                <Button className="modal-save-btn" onClick={handleBasicInfoForm}>Save</Button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default BasicInfo;
