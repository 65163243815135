import { useState, useEffect } from 'react';
import axios from 'axios';
import Validation from '../../../Constants/Validation';
import {
  BASE_URL_SANDBOX,
  UPDATE_CUSTOMER_BASIC_INFO,
} from '../../../Constants/Config';
import { updateAuthUser } from '../../../redux/features/Authentication/Customer/authUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccoutInfoData } from '../../../redux/features/Account/accountInfoSlice';
import UseCookieStorage from '../../../Constants/UseCookieStorage';
import Cookies from 'js-cookie';

const BasicInfoLogic = ({ handleClose, userData }) => {
  const { isGuest } = useSelector((state) => state.authUser);
  const Dispatch = useDispatch();
  const { validateFirstName, validateLastName, validatePhoneNumber } =
    Validation();
  const [submitmessage, setsubmitmessage] = useState('');
  // console.log(userData)

  const emoji =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

  const Space = /^[^\s]+$/i;
  const PhoneNo = /^\d{10}$/;
  const Nameval = /^[a-zA-Z]+$/;

  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    errors: {
      firstname: '',
      lastname: '',
      phone: '',
    },
    // cursorposition: {
    //   firstname: 0,
    //   lastname: 0,
    //   phone: 0,
    // },
  });

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      firstname: userData && userData.f_name ? userData.f_name : '',
      lastname: userData && userData.l_name ? userData.l_name : '',
      phone: userData && userData.phone ? userData.phone : '',
    }));
  }, [userData]);

  const handleBasicInfoInput = async (event) => {
    let { errors } = values;
    let { cursorposition } = values;
    const fieldName = event.target.name;
    const fieldValue =
      fieldName === 'phone'
        ? event.target.value.replace(/[^0-9]/g, '')
        : event.target.value;
    const newCursorPosition = event.target.selectionStart;

    switch (fieldName) {
      case 'firstname':
        // cursorposition.firstname = newCursorPosition;
        // await validateFirstName(fieldValue, errors);
        if (fieldName === '') {
          errors.firstname = 'Please enter First Name ';
        } else if (emoji.test(fieldName)) {
          errors.firstname = 'Emoji not allowed';
        } else if (!Space.test(fieldName)) {
          errors.firstname = 'Space is not allow';
        } else if (!Nameval.test(fieldName)) {
          errors.firstname = 'Alphabets only';
        } else {
          errors.firstname = '';
        }
        break;
      case 'lastname':
        // cursorposition.lastname = newCursorPosition;
        // await validateLastName(fieldValue, errors);
        if (fieldName === '') {
          errors.lastname = 'Please enter Last Name ';
        } else if (emoji.test(fieldName)) {
          errors.lastname = 'Emoji not allowed';
        } else if (!Space.test(fieldName)) {
          errors.lastname = 'Space is not allow';
        } else if (!Nameval.test(fieldName)) {
          errors.lastname = 'Alphabets only';
        } else {
          errors.lastname = '';
        }

        break;
      case 'phone':
        // cursorposition.phone = newCursorPosition;
        // await validatePhoneNumber(fieldValue, errors);
        if (fieldName === '') {
          errors.phone = 'Please enter phone number';
        } else if (emoji.test(fieldName)) {
          errors.phone = 'Emoji not allowed';
        } else if (!PhoneNo.test(fieldName)) {
          errors.phone = 'Phone no not valid';
        } else {
          errors.phone = '';
        }
        break;
      default:
        break;
    }

    setValues((prevValues) => ({
      errors,
      cursorposition,
      ...prevValues,
      [fieldName]: fieldValue,
    }));
  };
  const updateLocalStorage = (key, updates) => {
    // Step 1: Retrieve existing data from localStorage
    // const existingData = JSON.parse(localStorage.getItem(key)) || {};
    const existingData = JSON.parse(Cookies.get('userLoginData')) || {};
    const expireTime = new Date().getTime() + 2 * 60 * 60 * 1000;
    // Step 2: Update data for specific keys
    Object.assign(existingData, updates);

    // Step 3: Save the updated data back to localStorage
    // localStorage.setItem(key, JSON.stringify(existingData));
    UseCookieStorage(
      'userLoginData',
      JSON.stringify(existingData),
      new Date(expireTime),
    );
  };
  const handleBasicInfoForm = async (e) => {
    e.preventDefault();
    let { errors } = values;
    await validateFirstName(values.firstname, errors);
    await validateLastName(values.lastname, errors);
    await validatePhoneNumber(values.phone, errors);

    if (
      errors.firstname === '' &&
      errors.lastname === '' &&
      errors.phone === ''
    ) {
      const data = {
        id: userData && userData.id ? userData.id : '',
        f_name: values.firstname,
        l_name: values.lastname,
        phone: values.phone,
      };
      try {
        const response = await axios.post(
          BASE_URL_SANDBOX + UPDATE_CUSTOMER_BASIC_INFO,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );
        // console.log(response.data.message);x
        if (response.data.status && response.data.status === 200) {
          let data = {
            id: userData && userData.id,
          };
          if (isGuest === 'yes') {
            data.is_guest = 'yes';
          }

          Dispatch(fetchAccoutInfoData(data));
          updateLocalStorage('userLoginData', {
            f_name: values.firstname,
            l_name: values.lastname,
            name: `${values.firstname} ${values.lastname}`,
            phone: values.phone,
          });
          Dispatch(
            updateAuthUser({
              f_name: values.firstname,
              l_name: values.lastname,
              name: `${values.firstname} ${values.lastname}`,
              phone: values.phone,
            }),
          );
          handleClose();
        } else {
          setsubmitmessage(response.data.message);
          handleClose();
        }
      } catch (error) {
        return new Error(error);
      }
    } else {
      // console.log(errors.firstname , errors.lastname ,  errors.phone)
    }

    setValues((prevState) => ({
      ...prevState,
      errors,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleBasicInfoForm(e);
    }
  };
  return {
    handleBasicInfoInput,
    values,
    handleBasicInfoForm,
    submitmessage,
    setsubmitmessage,
    handleKeyDown,
  };
};

export default BasicInfoLogic;
