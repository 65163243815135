import React, { useState, useEffect } from 'react';
import QuickveeLogo from '../../../Assets/LoginScreen/quickveeLogo.svg';
import { Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link, useNavigate } from 'react-router-dom';
import {
  setAuthUser,
  setAuthentication,
  setGuestLogin,
} from '../../../redux/features/Authentication/Customer/authUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setWishlist } from '../../../redux/features/Wishlist/wishlistSlice';
import { setCartData } from '../../../redux/features/Cart/cartSlice';
import { addAddress } from '../../../redux/features/Account/deliveryAddressSlice';
import QuickveeLogoSymbol from './../../../Assets/Store/Mask Group 5941.svg';
import userLogo from './../../../Assets/Store/Mask Group 5930.svg';
import { useMediaQuery } from '@mui/material';
import Cookies from 'js-cookie';
const TopBar = ({ merchantId, orderMethod, setVisible }) => {
  const { isGuest } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [showuserData, setShowuserData] = useState(false);
  const authUserDataSate = useSelector((state) => state.authUser.UserData);
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleLogout = () => {
    localStorage.removeItem('is_guest');
    localStorage.removeItem('radius');
    localStorage.removeItem('guestDelivery_address');
    localStorage.removeItem('userLoginData');
    localStorage.removeItem('guestAddDeliveryAddress');
    Cookies.remove('userLoginData');
    Cookies.remove('is_guest');
    dispatch(setGuestLogin(''));
    dispatch(setAuthUser(null));
    dispatch(setAuthentication(false));
    dispatch(setWishlist([]));
    // setVisible("myPayment")
    dispatch(setCartData([]));
    const cartKey = `cart_${merchantId}`;
    localStorage.removeItem(cartKey);
    dispatch(addAddress([]));
    Navigate(`/merchant/${merchantId}?orderMethod=${orderMethod}`);
  };

  const handleProfileDataVisible = (event) => {
    event.stopPropagation();
    setShowuserData(!showuserData);
  };

  const handleProfileDataVisibleScreen = () => {
    setShowuserData(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleProfileDataVisibleScreen);
    return () => {
      document.addEventListener('click', handleProfileDataVisibleScreen);
    };
  }, []);

  useEffect(() => {
    // console.log(orderMethod);
  }, [orderMethod]);

  return (
    <>
      <div>
        <div className="main-cart-top-bar">
          <Link to={`/merchant/${merchantId}?orderMethod=${orderMethod}`}>
            {isMobile ? (
              <>
                {' '}
                <img
                  src={QuickveeLogoSymbol}
                  height={44}
                  alt="Quickvee"
                  loading="lazy"
                />
              </>
            ) : (
              <img
                src={QuickveeLogo}
                alt="quickvee"
                className="quickvee-logo-order-top-bar"
              />
            )}
          </Link>

          <div className="top-cart-bar-button ">
            {authUserDataSate?.id ? (
              <div
                className="top-bar-button"
                style={{ cursor: 'pointer' }}
                onClick={handleProfileDataVisible}
              >
                {isMobile ? (
                  <div className="profile-page-user-data-button">
                    <img
                      style={{ width: '28px' }}
                      src={userLogo}
                      className="mobile-user-logo"
                      alt="userLogo"
                    />
                  </div>
                ) : (
                  <>
                    <span style={{ cursor: 'pointer' }}>
                      {' '}
                      {authUserDataSate?.name.split(' ')[0]}
                    </span>{' '}
                  </>
                )}
                {isMobile ? (
                  ''
                ) : (
                  <span className="online-o-page-profile-drop-btn">
                    <KeyboardArrowDownIcon />
                  </span>
                )}

                <div
                  className="online-o-page-profile-box"
                  style={{ display: showuserData ? 'inline-table' : 'none' }}
                >
                  {isGuest !== 'yes' ? (
                    <>
                      <Link
                        className="profile-bar-link"
                        to="/order/profile"
                        state={{
                          merchantId: merchantId,
                          orderMethod: orderMethod,
                        }}
                      >
                        My Profile
                      </Link>
                      <Link
                        className="profile-bar-link"
                        to="/order/order-history"
                        state={{
                          merchantId: merchantId,
                          orderMethod: orderMethod,
                        }}
                      >
                        Order
                      </Link>
                    </>
                  ) : (
                    ''
                  )}
                  <Button
                    className="profile-bar-link profile-bar-link-logout"
                    style={{
                      borderBottom: isGuest !== 'yes' ? 'none' : 'none',
                    }}
                    onClick={() => handleLogout()}
                    id="Logout-btn"
                  >
                    Logout
                  </Button>
                </div>
              </div>
            ) : (
              <Link
                to="/customer-login"
                state={{
                  merchantId: merchantId,
                  orderMethod: orderMethod,
                  page: 'cart',
                }}
                className="quickvee-customer-login"
              >
                Log In
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
