// export function formatNumber(input) {
//   if (!input) return '0.00';

//   // Parse input as a number
//   const num = parseFloat(input);

//   // Round to 3 decimal places and convert to string
//   let rounded = num.toFixed(3);

//   // Remove unnecessary trailing zeros
//   rounded = rounded.replace(/(\.\d*[1-9])0+$|\.0*$/, '$1');

//   return rounded;
// }

export function formatNumber(input) {
  if (!input) return '0.00';

  // Parse input as a number
  const num = parseFloat(input);

  // Round to 3 decimal places
  let rounded = num.toFixed(3);

  // Ensure the result has at least two decimal places
  rounded = rounded.replace(/(\.\d{2})0+$/, '$1'); // Remove trailing zeros after the second decimal place
  rounded = rounded.replace(/(\.\d{1})$/, '$10'); // Add a zero if there's only one digit after the decimal

  return rounded;
}

const isGreaterThan10 = (num) => (+num >= 10 ? num : `0${num}`);

export const formatDate = (inputDate, type) => {
  const parsedTime = new Date(inputDate);
  if (isNaN(parsedTime.getTime())) {
    return <div>Error: Invalid time format</div>;
  }

  const day = parsedTime.getDate();
  const month = parsedTime.toLocaleString('default', { month: 'short' });
  const year = parsedTime.getFullYear();
  let hours = parsedTime.getHours();
  const minutes = parsedTime.getMinutes();
  const seconds = parsedTime.getSeconds();
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;

  let formattedTime = `${month} ${day}, ${year} ${isGreaterThan10(
    hours,
  )}:${isGreaterThan10(minutes)}:${isGreaterThan10(seconds)} ${meridiem}`;

  if (type === 'expiry-date') {
    formattedTime = `${month} ${day}, ${year}`;
  }

  if (type === 'future-order') {
    formattedTime = `${month} ${day}, ${year} ${isGreaterThan10(
      hours,
    )}:${isGreaterThan10(minutes)} ${meridiem}`;
  }
  return formattedTime;
};
