import React, { useEffect, useState } from 'react';
import TopBar from './TopBar';
import PaymentStepper from '../Cart/PaymentStepper';
import {
  setAuthUser,
  setAuthentication,
} from '../../../redux/features/Authentication/Customer/authUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleStoresData } from '../../../redux/features/SingleStore/singleStoresSlice';
import { fetchSingleStoreProductData } from '../../../redux/features/SingleStore/singleStoreProductDataSlice';
import { fetchVariantCartData } from '../../../redux/features/Cart/variantCartSlice';
import { fetchTaxDetailData } from '../../../redux/features/Payment/taxDetailSlice';
import { setCartData } from '../../../redux/features/Cart/cartSlice';
import Validation from '../../../Constants/Validation';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchLpscPaymentData } from '../../../redux/features/LPSC_Payment/lpscPayment';
import { DateTime } from 'luxon';
import { fetchAccoutInfoData } from '../../../redux/features/Account/accountInfoSlice';
import { fetchAllUserCards } from '../../../redux/features/Account/userCardsSlice';
import { fetchAllCartProductDetails } from '../../../redux/features/Cart/cartProductDetailsSlice';
import Footer from '../../MainHome/Footer';

const MainCart = () => {
  const location = useLocation();
  const [visible, setVisible] = useState(
    sessionStorage.getItem('orderStatus') ?? 'myPayment',
  );
  const { isGuest } = useSelector((state) => state.authUser);

  const searchParams = new URLSearchParams(location.search);
  const merchantId = location.pathname.replace('/payment-cart/', '');
  const localCartData = JSON.parse(localStorage.getItem(`cart_${merchantId}`));
  const dispatch = useDispatch();
  const cartDataState = useSelector((state) => state.cart.cartData);
  const { filterVarientDataFromCart } = Validation();
  const { UserData } = useSelector((state) => state.authUser);
  const orderMethod = searchParams.get('orderMethod');
  const singleStoreData = useSelector(
    (state) => state?.singleStores?.storeData?.store_data,
  );
  const AuthUserState = useSelector((state) => state?.authUser?.UserData);
  const [storeData, setStoreData] = useState('');

  const timerSelector = useSelector((state) => state.singleStores);
  const [activeHours, setActiveHours] = useState(false);
  const navigate = useNavigate('');

  useEffect(() => {
    const invalidOrderMethod =
      orderMethod !== 'delivery' && orderMethod !== 'pickup';

    if (location.pathname.includes('undefined') || invalidOrderMethod) {
      navigate('/');
    }
  }, [location, orderMethod]);

  useEffect(() => {
    if (localCartData && merchantId) {
      dispatch(setCartData(localCartData));

      const productIdsString = localCartData
        ?.map((item) => item.productId)
        .join(',');

      const data = {
        merchant_id: merchantId,
        product_id: productIdsString,
      };

      dispatch(fetchAllCartProductDetails(data));
    }
  }, [merchantId]);

  useEffect(() => {
    if (cartDataState && cartDataState.length >= 1) {
      const cartdata = filterVarientDataFromCart(localCartData, merchantId);

      // filterVarientDataFromCart(localCartData, merchantId)
      if (Array.isArray(cartdata) && cartdata.length >= 1) {
        cartdata.forEach((variant) => {
          variant && dispatch(fetchVariantCartData(variant));
        });
      }
    }
  }, [cartDataState]);

  useEffect(() => {
    if (UserData) {
      dispatch(setAuthUser(UserData));
    }
  }, []);

  useEffect(() => {
    if (AuthUserState?.id) {
      dispatch(
        fetchLpscPaymentData({
          merchant_id: merchantId,
          customer_id: AuthUserState?.id,
        }),
      );
    }
  }, [AuthUserState]);

  useEffect(() => {
    const data = {
      merchant_id: merchantId,
      orderMethod: orderMethod,
      distance: ' ',
      ind_brn: 'no',
    };
    const data_p = {
      merchant_id: merchantId,
      orderMethod: orderMethod,
      category_id: '',
    };
    const taxdata = {
      merchant_id: merchantId,
    };
    if (merchantId && orderMethod) {
      // console.log(merchantId, orderMethod);
      dispatch(fetchSingleStoresData(data));
      dispatch(fetchSingleStoreProductData(data_p));
      dispatch(fetchTaxDetailData(taxdata));
    }
  }, [orderMethod, merchantId]);

  useEffect(() => {
    timeSlote();
  }, [timerSelector]);

  const timeSlote = () => {
    const time_zone = timerSelector?.storesData?.store_data?.mer_timeZone;
    timerSelector.storesData?.store_data?.time_slot &&
      timerSelector.storesData.store_data.time_slot.forEach((result) => {
        const utcDateTime = DateTime.utc();
        const localDateTime = utcDateTime.setZone(time_zone);
        const dayName = localDateTime.toLocaleString({ weekday: 'long' });
        // console.log(dayName)
        const curr_time = localDateTime.toFormat('HH:mm');
        if (dayName === result.day_name) {
          let open_time = result.open_time.split(',');
          let close_time = result.close_time.split(',');
          // console.log(open_time)
          // console.log(close_time)
          const convertToMinutes = (time) => {
            const [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes;
          };

          const currTimeMinutes = convertToMinutes(curr_time);
          let isActive = false;
          open_time.forEach((open, index) => {
            const openTime = open;
            const closeTime = close_time[index];
            const openTimeMinutes = convertToMinutes(openTime);
            const closeTimeMinutes = convertToMinutes(closeTime);

            // console.log(currTimeMinutes)
            if (
              openTimeMinutes <= currTimeMinutes &&
              currTimeMinutes <= closeTimeMinutes
            ) {
              isActive = true;
              return;
            }
          });
          setActiveHours(isActive);

          // console.log('Yes, it matches the day');
        } else {
          // console.log('No, it does not match the day');
        }
      });
  };

  useEffect(() => {
    const data = {
      id: AuthUserState?.id,
      merchant_id: merchantId,
    };
    const data_card = {
      user_id: AuthUserState?.id,
      merchant_id: merchantId,
    };
    if (isGuest === 'yes') {
      data.is_guest = 'yes';
    }
    // console.log(data)
    if (data?.id && merchantId) {
      dispatch(fetchAccoutInfoData(data));
      dispatch(fetchAllUserCards(data_card));
    }
  }, [AuthUserState]);

  return (
    <>
      <div className="d-flex flex-column ">
        <div style={{ minHeight: 'calc(100vh - 80px)' }}>
          <TopBar
            merchantId={merchantId}
            orderMethod={orderMethod}
            setVisible={setVisible}
            activeHours={activeHours}
          />
          <PaymentStepper
            merchantId={merchantId}
            orderMethod={orderMethod}
            setVisible={setVisible}
            visible={visible}
            activeHours={activeHours}
          />
        </div>
        <div className="MainCart-footer mt-auto">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MainCart;
